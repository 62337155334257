import React from 'react';
import { isNumber } from 'lodash';
import { Skeleton } from 'antd';
import { gql, useQuery } from '@apollo/client';
export const GET_DRIVER_GROUP = gql `
  query getDriverGroup($groupID: Int!) {
    driverGroup(groupID: $groupID) {
      name
    }
  }
`;
const DriverGroup = (props) => {
    var _a;
    const { groupID } = props;
    const { loading, error, data } = useQuery(GET_DRIVER_GROUP, {
        variables: {
            groupID,
        },
        skip: !isNumber(groupID),
    });
    if (loading) {
        return React.createElement(Skeleton, { paragraph: { rows: 1, width: 10 } });
    }
    if (error) {
        return React.createElement("span", null, `ID<${groupID}>`);
    }
    return React.createElement("span", null, (_a = data === null || data === void 0 ? void 0 : data.driverGroup) === null || _a === void 0 ? void 0 : _a.name);
};
export default DriverGroup;
