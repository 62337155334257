import React, { useState } from 'react';
import { some, split, map, isFinite } from 'lodash';
import moment from 'moment';
import { Input, DatePicker } from 'antd';
import { Filter, FilterItem } from '@components';
const { RangePicker } = DatePicker;
const RequestInputForm = (props) => {
    const { loading, onSubmit } = props;
    const [dirty, setDirty] = useState(false);
    return (React.createElement(Filter, { loading: loading, disabled: !dirty, defaultValue: {}, onApply: (x) => onSubmit(x), serializers: {
            timeRange: ([from, to]) => (from === null || from === void 0 ? void 0 : from.unix()) + ',' + (to === null || to === void 0 ? void 0 : to.unix()),
        }, deserializers: {
            timeRange: (time) => map(split(time, ',', 2), (t) => {
                const unixSeconds = parseInt(t, 10);
                if (isFinite(unixSeconds)) {
                    return moment.unix(unixSeconds);
                }
                return undefined;
            }),
        }, onFieldsChange: (_, fields) => {
            setDirty(some(fields, 'dirty'));
        } },
        React.createElement(FilterItem, { label: "City ID", id: "cityID", fieldOptions: {
                rules: [{ required: true }],
            } },
            React.createElement(Input, null)),
        React.createElement(FilterItem, { label: "Driver ID", id: "driverID", fieldOptions: {
                rules: [{ required: true }],
            } },
            React.createElement(Input, null)),
        React.createElement(FilterItem, { label: "Time Range", id: "timeRange", fieldOptions: {
                rules: [{ required: true }],
            } },
            React.createElement(RangePicker, { showTime: { format: 'HH:mm' }, format: "YYYY-MM-DD HH:mm", placeholder: ['Start Time', 'End Time'] }))));
};
export default RequestInputForm;
