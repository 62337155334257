import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { isAuthenticated } from 'grab-login/lib/helper';
const ProtectedRoute = ({ authRequired, checkPermission, children, }) => {
    const location = useLocation();
    if (authRequired && !isAuthenticated()) {
        return React.createElement(Navigate, { to: "/login", state: { from: location.pathname } });
    }
    if (checkPermission && !checkPermission()) {
        return React.createElement(Navigate, { to: "/unauthorized" });
    }
    return children;
};
ProtectedRoute.defaultProps = {
    authRequired: true,
};
export default ProtectedRoute;
