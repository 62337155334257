export const STG_DUMMY_DATA = {
    getShiftPerformance: {
        performanceDetails: [
            {
                cityID: 6,
                dataSection: [
                    {
                        name: 'AR/CR/CP',
                        items: [
                            {
                                name: 'accepted_trips',
                                value: '425/500',
                            },
                            {
                                name: 'accepted_rate',
                                value: '85%',
                            },
                            {
                                name: 'cancelled_trips',
                                value: '25/500',
                            },
                            {
                                name: 'cancelled_rate',
                                value: '10%',
                            },
                        ],
                    },
                    {
                        name: 'Capacity',
                        items: [
                            {
                                name: 'subscribed_driver',
                                value: '100',
                            },
                            {
                                name: 'total_capacity',
                                value: '150',
                            },
                            {
                                name: 'cancelled_shift_driver',
                                value: '20',
                            },
                        ],
                    },
                    {
                        name: 'Payout',
                        items: [
                            {
                                name: 'total_mfg',
                                value: '1000.20',
                            },
                        ],
                    },
                    {
                        name: 'Others',
                        items: [
                            {
                                name: 'oh',
                                value: '2.4 hr/90%',
                            },
                            {
                                name: 'tpoh',
                                value: '2.4 hr',
                            },
                        ],
                    },
                ],
                endTime: '2023-01-01T12:00:00Z',
                geofenceCode: 'BEDOK',
                id: 1,
                label: ['HighD2R', 'LowTPOH'],
                metrics: [],
                shiftID: 1,
                startTime: '2023-01-01T10:00:00Z',
                state: 'SUCCESS',
            },
        ],
    },
    getShiftsPerformanceGraphSummary: {
        metrics: [
            {
                title: `shift_subscription_distribution`,
                xFieldName: 'hour_diff',
                yFieldName: 'total_driver',
                data: [
                    { xFieldValue: 150, yFieldValue: 20 },
                    { xFieldValue: 120, yFieldValue: 6 },
                    { xFieldValue: 100, yFieldValue: 3 },
                    { xFieldValue: 80, yFieldValue: 2 },
                    { xFieldValue: 20, yFieldValue: 1 },
                    { xFieldValue: 0, yFieldValue: 1 },
                ],
            },
            {
                title: `mfg_bonus_distribution`,
                xFieldName: 'total_payout',
                yFieldName: 'total_driver',
                data: [
                    { xFieldValue: 0, yFieldValue: 3 },
                    { xFieldValue: 5000, yFieldValue: 6 },
                    { xFieldValue: 10000, yFieldValue: 3 },
                    { xFieldValue: 15000, yFieldValue: 2 },
                    { xFieldValue: 20000, yFieldValue: 2 },
                    { xFieldValue: 25000, yFieldValue: 1 },
                ],
            },
            {
                title: `shift_subscription_distribution`,
                xFieldName: 'hour_diff',
                yFieldName: 'total_driver',
                data: [
                    { xFieldValue: 150, yFieldValue: 20 },
                    { xFieldValue: 120, yFieldValue: 6 },
                    { xFieldValue: 100, yFieldValue: 3 },
                    { xFieldValue: 80, yFieldValue: 2 },
                    { xFieldValue: 20, yFieldValue: 1 },
                    { xFieldValue: 0, yFieldValue: 1 },
                ],
            },
            {
                title: `mfg_bonus_distribution`,
                xFieldName: 'total_payout',
                yFieldName: 'total_driver',
                data: [
                    { xFieldValue: 0, yFieldValue: 3 },
                    { xFieldValue: 5000, yFieldValue: 6 },
                    { xFieldValue: 10000, yFieldValue: 3 },
                    { xFieldValue: 15000, yFieldValue: 2 },
                    { xFieldValue: 20000, yFieldValue: 2 },
                    { xFieldValue: 25000, yFieldValue: 1 },
                ],
            },
            {
                title: `shift_subscription_distribution`,
                xFieldName: 'hour_diff',
                yFieldName: 'total_driver',
                data: [
                    { xFieldValue: 150, yFieldValue: 20 },
                    { xFieldValue: 120, yFieldValue: 6 },
                    { xFieldValue: 100, yFieldValue: 3 },
                    { xFieldValue: 80, yFieldValue: 2 },
                    { xFieldValue: 20, yFieldValue: 1 },
                    { xFieldValue: 0, yFieldValue: 1 },
                ],
            },
            {
                title: `mfg_bonus_distribution`,
                xFieldName: 'total_payout',
                yFieldName: 'total_driver',
                data: [
                    { xFieldValue: 0, yFieldValue: 3 },
                    { xFieldValue: 5000, yFieldValue: 6 },
                    { xFieldValue: 10000, yFieldValue: 3 },
                    { xFieldValue: 15000, yFieldValue: 2 },
                    { xFieldValue: 20000, yFieldValue: 2 },
                    { xFieldValue: 25000, yFieldValue: 1 },
                ],
            },
        ],
    },
};
