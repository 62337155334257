import React, { useState } from 'react';
import { Alert, Result, Spin } from 'antd';
import { size } from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { SUGGESTION } from '@gql/fragments/heatmapsSuggestion';
import RequestInputForm from './RequestInputForm';
import SuggestionList from './SuggestionList';
import { isPrd } from '@helpers/env';
import styles from './SuperchargedDaxDebuggerPage.less';
export const GET_SUGGESTIONS = gql `
  ${SUGGESTION}
  query getSuggestions(
    $cityID: Float
    $driverID: Float
    $fromTime: DateTime
    $toTime: DateTime
  ) {
    heatmapsSuggestions(
      cityID: $cityID
      driverID: $driverID
      fromTime: $fromTime
      toTime: $toTime
    ) {
      suggestions {
        ...SuggestionWithEvents
      }
    }
  }
`;
const SuperchargedDaxDebuggerPage = () => {
    var _a, _b, _c;
    const [input, setInput] = useState({
        cityID: 0,
        driverID: 0,
        timeRange: [],
    });
    const { loading: suggestionLoading, error: suggestionError, data: suggestionData, } = useQuery(GET_SUGGESTIONS, {
        variables: {
            cityID: input.cityID,
            driverID: input.driverID,
            fromTime: (_a = input.timeRange[0]) === null || _a === void 0 ? void 0 : _a.format(),
            toTime: (_b = input.timeRange[1]) === null || _b === void 0 ? void 0 : _b.format(),
        },
        skip: input.cityID <= 0 || input.driverID <= 0 || size(input.timeRange) < 2,
    });
    const suggestions = (_c = suggestionData === null || suggestionData === void 0 ? void 0 : suggestionData.heatmapsSuggestions) === null || _c === void 0 ? void 0 : _c.suggestions;
    return (React.createElement("div", { className: styles.page },
        !isPrd() && (React.createElement(Alert, { className: styles.alert, message: "Warning: This tool may not work due to some data not available in non production environment", type: "warning", closable: true })),
        React.createElement(RequestInputForm, { loading: suggestionLoading, onSubmit: (val) => {
                setInput(val);
            } }),
        !!suggestionError && (React.createElement(Result, { status: "error", title: "Failed to load suggestions" })),
        !!suggestionLoading && React.createElement(Spin, null),
        !suggestionError && !suggestionLoading && (React.createElement(SuggestionList, { driverID: input.driverID, suggestions: suggestions }))));
};
export default SuperchargedDaxDebuggerPage;
