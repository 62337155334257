import React, { useState } from 'react';
import { Alert } from 'antd';
import I18n from 'i18n-js';
import moment from 'moment';
import { gql, useQuery } from '@apollo/client';
import { isPrd } from '@helpers/env';
import { isEmpty, size } from 'lodash';
import { getCountries } from '@helpers/auth';
import styles from './ShiftPerformance.less';
import ShiftPerformanceFilter from './filter';
import ShiftPerformanceSummaryData from './ShiftPerformanceTable';
import { Spinner } from '@components/index';
import { Column } from '@ant-design/charts';
import { FilterShiftPerformanceAggregateTime } from './filter/Filter';
import { STG_DUMMY_DATA } from './constant';
import { SHIFT_PERFORMANCE_ROW } from '@gql/fragments/shift';
const GET_SHIFT_PERFORMANCE_GRAPH = gql `
  query getShiftPerformanceGraph(
    $cityID: Int
    $geofenceCode: String
    $startDate: String
    $endDate: String
    $aggregateByTime: String
  ) {
    getShiftsPerformanceGraphSummary(
      cityID: $cityID
      geofenceCode: $geofenceCode
      startDate: $startDate
      endDate: $endDate
      aggregateByTime: $aggregateByTime
    ) {
      metrics {
        title
        xFieldName
        yFieldName
        yFieldSize
        data {
          xFieldValue
          yFieldValue
        }
      }
    }
  }
`;
const GET_SHIFT_PERFORMANCE_LIST = gql `
  ${SHIFT_PERFORMANCE_ROW}
  query getShiftPerformanceRow(
    $shiftID: Int
    $cityID: Int
    $geofenceCode: String
    $startDate: String
    $endDate: String
    $limit: Int
    $offset: Int
  ) {
    getShiftPerformance(
      shiftID: $shiftID
      cityID: $cityID
      geofenceCode: $geofenceCode
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
    ) {
      ...shiftPerformanceRow
    }
  }
`;
const ShiftPerformancePage = () => {
    var _a, _b;
    const countries = getCountries();
    const defaultFilterValue = {
        countryId: (_a = countries === null || countries === void 0 ? void 0 : countries[0]) === null || _a === void 0 ? void 0 : _a.id,
        cityId: undefined,
        startDate: moment().add(-1, 'weeks').format('YYYY-MM-DD'),
        endDate: moment().add(-1, 'days').format('YYYY-MM-DD'),
        geofenceCode: '',
        aggregateTime: FilterShiftPerformanceAggregateTime.DAILY,
    };
    const [filter, setFilter] = useState(defaultFilterValue);
    const handleFilterApply = (filterVal) => {
        setFilter(filterVal);
    };
    const { loading: shiftPerformanceLoading, error: shiftPerformanceError, data: shiftPerformanceData, fetchMore, } = useQuery(GET_SHIFT_PERFORMANCE_LIST, {
        variables: {
            cityID: filter.cityId,
            geofenceCode: filter.geofenceCode,
            startDate: filter.startDate,
            endDate: filter.endDate,
            offset: 0,
            limit: 20,
            aggregateByTime: filter.aggregateTime,
        },
        skip: !filter.cityId,
    });
    const { loading: shiftPerformanceGraphLoading, error: shiftPerformanceGraphError, data: shiftPerformanceGraphData, } = useQuery(GET_SHIFT_PERFORMANCE_GRAPH, {
        variables: {
            cityID: filter.cityId,
            geofenceCode: filter.geofenceCode,
            startDate: filter.startDate,
            endDate: filter.endDate,
            aggregateByTime: filter.aggregateTime,
        },
        skip: !filter.cityId,
    });
    const generateData = (metric) => {
        const data = metric.data.map((m) => {
            return {
                [metric.xFieldName]: m.xFieldValue,
                [metric.yFieldName]: m.yFieldValue,
            };
        });
        return Object.assign(Object.assign({}, metric), { data: data });
    };
    const generateGraphConfig = (metric) => {
        return {
            data: generateData(metric).data,
            height: 200,
            width: 300,
            autoFit: false,
            xField: metric.xFieldName,
            yField: metric.yFieldName,
            minColumnWidth: 10,
            columnStyle: {
                fill: 'green',
                fillOpacity: 0.8,
                cursor: 'pointer',
            },
            label: {
                type: 'outer',
                style: {
                    overflow: 'visible',
                },
            },
        };
    };
    let shiftPerformanceGraph = (_b = shiftPerformanceGraphData === null || shiftPerformanceGraphData === void 0 ? void 0 : shiftPerformanceGraphData.getShiftsPerformanceGraphSummary) === null || _b === void 0 ? void 0 : _b.metrics;
    if (!isPrd()) {
        shiftPerformanceGraph =
            STG_DUMMY_DATA.getShiftsPerformanceGraphSummary.metrics;
    }
    if (shiftPerformanceLoading || shiftPerformanceGraphLoading) {
        return (React.createElement("div", null,
            React.createElement(ShiftPerformanceFilter, { defaultValue: filter, onApply: handleFilterApply }),
            React.createElement(Spinner, null)));
    }
    if (shiftPerformanceError) {
        React.createElement(Alert, { className: styles.alert, message: "error on getting shift performance, please contact @oncall-morpheus", type: "error", closable: true });
    }
    if (isEmpty(shiftPerformanceData === null || shiftPerformanceData === void 0 ? void 0 : shiftPerformanceData.getShiftPerformance) &&
        isEmpty(shiftPerformanceGraph) &&
        filter.cityId) {
        return (React.createElement("div", null,
            React.createElement(ShiftPerformanceFilter, { defaultValue: filter, onApply: handleFilterApply }),
            React.createElement("div", { className: styles.notFound },
                React.createElement("img", { src: require('public/images/empty-state.svg'), className: styles.notFoundImage }),
                React.createElement("h2", null, I18n.t('shift_performance.not_found_title')),
                React.createElement("div", null, I18n.t('shift_performance.not_found_description')))));
    }
    return (React.createElement("div", { className: styles.page },
        !isPrd() && (React.createElement(Alert, { className: styles.alert, message: "Warning: This tool may not work due to some data not available in non production environment", type: "warning", closable: true })),
        React.createElement(ShiftPerformanceFilter, { defaultValue: filter, onApply: handleFilterApply }),
        React.createElement("div", null,
            React.createElement("table", { className: styles.chartTable },
                React.createElement("tr", { className: styles.chartColumn }, shiftPerformanceGraph === null || shiftPerformanceGraph === void 0 ? void 0 : shiftPerformanceGraph.map((metric) => {
                    const config = generateGraphConfig(metric);
                    return (React.createElement("td", { className: styles.chartSection, key: metric.title },
                        React.createElement("h3", null, I18n.t(`shift_performance.${metric.title}`)),
                        React.createElement(Column, Object.assign({}, config, { legend: { position: 'bottom' } }))));
                }))),
            React.createElement(ShiftPerformanceSummaryData, { countryCode: filter.countryId, shiftPerformance: shiftPerformanceData === null || shiftPerformanceData === void 0 ? void 0 : shiftPerformanceData.getShiftPerformance, fetchMoreData: () => {
                    fetchMore({
                        variables: {
                            offset: size(shiftPerformanceData === null || shiftPerformanceData === void 0 ? void 0 : shiftPerformanceData.getShiftPerformance),
                        },
                    });
                } }))));
};
export default ShiftPerformancePage;
