import React from 'react';
import { isEmpty } from 'lodash';
import { Form, Tooltip } from 'antd';
const FilterItem = (props) => {
    const { label, tooltip, id, fieldOptions, children, form, getInitialValue } = props;
    if (!form) {
        window.console.log('Please use <FilterItem> inside <Filter>');
        return React.createElement("div", null, "ERR: Please use FilterItem inside Filter component");
    }
    const { getFieldDecorator } = form;
    const formItem = (React.createElement(Form.Item, { label: label }, getFieldDecorator(id, Object.assign(Object.assign({}, fieldOptions), { initialValue: getInitialValue === null || getInitialValue === void 0 ? void 0 : getInitialValue(id) }))(children)));
    if (isEmpty(tooltip))
        return formItem;
    return (React.createElement(Tooltip, { title: tooltip, placement: "bottom" }, formItem));
};
export default FilterItem;
