import { offsetLimitPagination } from '@apollo/client/utilities';
import { GET_SUGGESTIONS } from '@pages/tool/scd-debugger/SuperchargedDaxDebuggerPage';
const typePolicies = {
    Query: {
        fields: {
            schedules: offsetLimitPagination([
                'startTime',
                'endTime',
                'cityID',
                'geofenceCodes',
                'editableOnly',
            ]),
            shifts: offsetLimitPagination([
                'startTime',
                'endTime',
                'name',
                'cityID',
                'geofenceName',
                'state',
            ]),
            shiftTemplates: offsetLimitPagination([
                'startTime',
                'endTime',
                'name',
                'cityID',
                'geofenceName',
                'state',
            ]),
            getShiftPerformance: offsetLimitPagination([
                'shiftID',
                'cityID',
                'geofenceCode',
                'startDate',
                'endDate',
            ]),
            listStreak: offsetLimitPagination([
                'cityID',
                'fromTime',
                'toTime',
                'type',
                'streakStateFilter',
                'vanguardStateFilter',
            ]),
            driverLevers: offsetLimitPagination(['leverID', 'sortBy']),
        },
    },
    ShiftByScheduleType: {
        keyFields: ['scheduleID', 'id', 'shiftStartTime'],
    },
    ListShiftRow: {
        keyFields: ['shiftID'],
    },
    ShiftPerformance: {
        keyFields: ['shiftID'],
    },
    SuggestionWithEvents: {
        keyFields: ['suggestionUID'],
    },
    SuggestionSession: {
        keyFields: ['sessionUID'],
        fields: {
            suggestions: {
                read(_, { variables, readField, cache: thisCache }) {
                    var _a, _b;
                    const suggestionCachedQuery = thisCache.readQuery({
                        query: GET_SUGGESTIONS,
                        variables: {
                            cityID: variables === null || variables === void 0 ? void 0 : variables.cityID,
                            driverID: variables === null || variables === void 0 ? void 0 : variables.driverID,
                            sessionUID: readField('sessionUID'),
                        },
                    });
                    return (_b = (_a = suggestionCachedQuery === null || suggestionCachedQuery === void 0 ? void 0 : suggestionCachedQuery.heatmapsSuggestions) === null || _a === void 0 ? void 0 : _a.suggestions) !== null && _b !== void 0 ? _b : [];
                },
            },
        },
    },
    VanguardBatchList: {
        keyFields: ['batchID'],
    },
};
export default typePolicies;
