import { intersection, isEmpty } from 'lodash';
import { getCurrentUser } from './auth';
export var Resource;
(function (Resource) {
    Resource["SHIFT"] = "shift";
    Resource["AUTO_ZONING"] = "auto_zoning";
    Resource["STREAK"] = "streak";
    Resource["VANGUARD_STREAK"] = "vanguard_streak";
    Resource["EXPLAINABILITY"] = "explainability";
})(Resource || (Resource = {}));
export var Action;
(function (Action) {
    Action["READ"] = "read";
    Action["UPDATE"] = "update";
    Action["CREATE"] = "create";
    Action["DELETE"] = "delete";
})(Action || (Action = {}));
export const isPermitted = (resource, action) => {
    var _a;
    return !!((_a = getCurrentUser('permissions')[resource]) === null || _a === void 0 ? void 0 : _a[action]);
};
export const isPermittedAnyAction = (resource, actions) => {
    const allowedActions = getCurrentUser('permissions')[resource];
    return !isEmpty(intersection(allowedActions, actions));
};
