import React, { useEffect, Children, cloneElement, forwardRef, useImperativeHandle, } from 'react';
import { isNil, mapValues } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { Form, Button } from 'antd';
import styles from './Filter.less';
const FilterSearchParamKey = 'filter';
const serializeFilter = (formValue) => {
    return JSON.stringify(formValue);
};
const deserializeFilter = (param) => {
    if (!param)
        return {};
    return JSON.parse(param);
};
const Filter = forwardRef((props, ref) => {
    const { loading, disabled, defaultValue, onApply, children, form, serializers, deserializers, } = props;
    useImperativeHandle(ref, () => form);
    const [searchParams, setSearchParams] = useSearchParams();
    const getFilterValueFromSearchParam = (fieldName) => {
        var _a, _b;
        const filters = deserializeFilter(searchParams.get(FilterSearchParamKey));
        const value = filters[fieldName];
        return (_b = (_a = deserializers === null || deserializers === void 0 ? void 0 : deserializers[fieldName]) === null || _a === void 0 ? void 0 : _a.call(deserializers, value)) !== null && _b !== void 0 ? _b : value;
    };
    const setFilterValueToSearchParam = (value) => {
        const filterParamValue = serializeFilter(mapValues(value, (v, k) => { var _a, _b; return (_b = (_a = serializers === null || serializers === void 0 ? void 0 : serializers[k]) === null || _a === void 0 ? void 0 : _a.call(serializers, v)) !== null && _b !== void 0 ? _b : v; }));
        setSearchParams(Object.assign(Object.assign({}, searchParams), { [FilterSearchParamKey]: filterParamValue }));
    };
    useEffect(() => {
        // At start, try to submit if all fields are valid to load initial data
        // If not vavlid, reset and wait for further input by user
        form.validateFields((err, values) => {
            if (!err) {
                setFilterValueToSearchParam(values);
                onApply(values);
            }
            else {
                form.resetFields();
            }
        });
    }, []);
    const getInitialValue = (fieldName) => {
        const valueFromSearchParam = getFilterValueFromSearchParam(fieldName);
        if (!isNil(valueFromSearchParam)) {
            return valueFromSearchParam;
        }
        return defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue[fieldName];
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                setFilterValueToSearchParam(values);
                onApply(values);
            }
        });
    };
    return (React.createElement(Form, { className: styles.filter, layout: "inline", onSubmit: handleSubmit },
        React.createElement("div", { className: styles.attributes }, Children.map(children, (child) => cloneElement(child, {
            form: form,
            getInitialValue: getInitialValue,
        }))),
        React.createElement("div", { className: styles.buttons },
            React.createElement(Button, { onClick: () => {
                    form.resetFields();
                } }, "Reset"),
            React.createElement(Button, { type: "primary", htmlType: "submit", loading: loading, disabled: disabled }, "Search"))));
});
Filter.displayName = 'Filter';
export default Form.create({
    onFieldsChange: ({ onFieldsChange }, fields, allFields) => {
        onFieldsChange === null || onFieldsChange === void 0 ? void 0 : onFieldsChange(fields, allFields);
    },
    onValuesChange: ({ onValuesChange }, values, allValues) => {
        onValuesChange === null || onValuesChange === void 0 ? void 0 : onValuesChange(values, allValues);
    },
})(Filter);
