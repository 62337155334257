import React, { useState } from 'react';
import { map } from 'lodash';
import { Checkbox, Popover, Icon } from 'antd';
import styles from './FieldSelector.less';
const FieldSelector = (props) => {
    const { fields, getFieldName, onChange, values } = props;
    const [popoverOpen, setPopoverOpen] = useState(false);
    const fieldOptions = map(fields, (field) => ({
        label: getFieldName(field),
        value: field,
    }));
    const popoverContent = (React.createElement(Checkbox.Group, { className: styles.popoverContent, options: fieldOptions, value: values, onChange: onChange }));
    return (React.createElement(Popover, { placement: "bottom", content: popoverContent, title: "Fields", trigger: "click", visible: popoverOpen, onVisibleChange: setPopoverOpen },
        React.createElement(Icon, { type: "setting" })));
};
export default FieldSelector;
