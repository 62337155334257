import React from 'react';
import classnames from 'classnames';
import { map, size } from 'lodash';
import styles from './Funnel.less';
const Funnel = (props) => {
    const { className, data, onItemClick } = props;
    return (React.createElement("div", { className: classnames(styles.funnel, className), style: { '--total': size(data) } }, map(data, (d, i) => (React.createElement("div", { className: styles.funnelItem, key: d.id, style: { '--n': i }, onClick: () => {
            onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick(d.id);
        } }, d.text)))));
};
export default Funnel;
