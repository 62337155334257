import { isPrd } from './env';
// ================== Geo Tools ==================
const geoToolsBaseUri = () => window.Grab.config.geoToolsBaseUri;
export const GeoToolsUrl = {
    nonBookingTripsDebugger: (suggestionUid, driverID) => `${geoToolsBaseUri()}non-booking-trips/${suggestionUid}/${driverID}`,
    geofenceDetail: (geofenceCode) => `${geoToolsBaseUri()}ops-tools/geo-fen/detail/${geofenceCode}`,
    grabAreaDetail: (grabAreaID) => `${geoToolsBaseUri()}ops-tools/grab-area/detail?areaID=${grabAreaID}`,
};
// ================== Unicorn ==================
const unicornBaseUri = () => `https://${window.Grab.config.unicornBaseUri}/`;
export const UnicornUrl = {
    tasks: () => `${unicornBaseUri()}tasks`,
};
// ================== Gamma ==================
const gammaBaseUri = () => window.Grab.config.gammaBaseUri;
export const GammaUrl = {
    driver: (id) => `${gammaBaseUri()}drivers/${id}`,
    driverGroup: (driverGroupID) => `${gammaBaseUri()}driver_groups/${driverGroupID}`,
};
// ================== Phoenix ==================
// TODO change to use template in deploy repo
const phoenixBaseUri = () => isPrd() ? 'https://phoenix.grab.com/' : 'https://phoenix.stg-myteksi.com/';
export const PhoenixUrl = {
    driver: (id) => `${phoenixBaseUri()}drivers/${id}`,
};
// ================== Dexter ==================
// TODO change to use template in deploy repo
const dexterBaseUri = () => isPrd() ? 'https://dexter.grab.com/' : 'https://dexter.stg-myteksi.com/';
export const DexterUrl = {
    booking: (bookingCode) => `${dexterBaseUri()}bookings/${bookingCode}`,
};
