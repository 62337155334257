import React from 'react';
import { map } from 'lodash';
import styles from './InfoSection.less';
import { Divider } from 'antd';
const InfoSection = ({ info, showDivider }) => (React.createElement("div", { className: styles.section }, map(info, (subSection) => (React.createElement("div", { key: subSection.title },
    React.createElement("div", { className: styles.subSection },
        React.createElement("div", { className: styles.subSectionHeading },
            React.createElement("h2", { className: styles.title }, subSection.title),
            React.createElement("p", { className: styles.subTitle }, subSection.subTitle)),
        React.createElement("div", { className: styles.subSectionBody }, map(subSection.body, (body) => (React.createElement("div", { className: styles.block, key: body.label },
            React.createElement("div", { className: styles.label }, body.label),
            React.createElement("div", { className: styles.value }, body.value)))))),
    React.createElement(Divider, { style: {
            display: showDivider ? 'block' : 'none',
        } }))))));
export default InfoSection;
