import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Notification, Button } from 'grab-ui';
import serviceWorker from '../helpers/serviceWorker';

export default class ServiceWorkerWrapper extends Component {
  static defaultProps = {
    description:
      'A new version has been downloaded, please refresh the page to update to latest version.',
    title: 'New Version Update',
    refreshButton: (
      <Button type="primary" size="small" onClick={() => location.reload()}>
        Refresh
      </Button>
    ),
    showRefreshButton: false,
  };

  static propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.element.isRequired,
    refreshButton: PropTypes.element,
    showRefreshButton: PropTypes.bool,
  };

  componentDidMount() {
    serviceWorker.register(this.onServiceWorkerUpdate.bind(this));
  }

  onServiceWorkerUpdate(eventName) {
    if (eventName === 'update-installed') {
      Notification.info({
        description: this.props.description,
        duration: 0,
        message: this.props.title,
        placement: 'bottomLeft',
        btn: this.props.showRefreshButton
          ? this.props.refreshButton
          : undefined,
      });
    }
  }

  render() {
    return this.props.children;
  }
}

export function withServiceWorker(options) {
  return function decorate(WrappedComponent) {
    const wrapper = props => (
      <ServiceWorkerWrapper {...options}>
        <WrappedComponent {...props} />
      </ServiceWorkerWrapper>
    );
    wrapper.displayName = `withServiceWorker(${WrappedComponent.displayName ||
      WrappedComponent.name})`;
    return wrapper;
  };
}
