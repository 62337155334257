import React, { useState } from 'react';
import { map, debounce, find } from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { Select } from 'antd';
import styles from './GeofenceInput.less';
export const GEOFENCES = gql `
  query getGeofences(
    $cityCode: String
    $input: String
    $offset: Float
    $limit: Float
  ) {
    geofences(
      cityCode: $cityCode
      input: $input
      offset: $offset
      limit: $limit
    ) {
      cityId
      countryId
      name
      code
      version
    }
  }
`;
const GeofenceInput = (props) => {
    const { className, cityCode, value, onSelect, allowClear, disabled } = props;
    const [searchText, setSearchText] = useState('');
    const { loading: geofencesLoading, error: geofencesError, data: geofencesData, } = useQuery(GEOFENCES, {
        variables: {
            cityCode,
            input: searchText,
            offset: 0,
            limit: 50,
        },
        skip: !cityCode || searchText.length < 3,
    });
    const searchGeofences = debounce((input) => {
        setSearchText(input);
    }, 300);
    const handleSelect = (code) => {
        const geo = find(geofencesData === null || geofencesData === void 0 ? void 0 : geofencesData.geofences, { code });
        const { cityId, name, version } = geo;
        onSelect({
            cityId,
            name,
            code,
            version,
        });
    };
    return (React.createElement(Select, { showSearch: true, allowClear: !!allowClear, defaultActiveFirstOption: false, className: className, placeholder: "Geofence", onSearch: searchGeofences, value: value, filterOption: false, disabled: !cityCode || disabled, onSelect: handleSelect, onChange: (code) => {
            if (allowClear && !code) {
                setSearchText('');
                onSelect(undefined);
            }
        }, optionLabelProp: "value" }, map(geofencesData === null || geofencesData === void 0 ? void 0 : geofencesData.geofences, ({ code, name }) => (React.createElement(Select.Option, { key: code, value: code, title: name },
        name,
        React.createElement("br", null),
        React.createElement("span", { className: styles.geofenceCode }, code))))));
};
export default GeofenceInput;
