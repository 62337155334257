import { ApolloClient, InMemoryCache, from } from '@apollo/client';

import authLink from './links/authLink';
import httpLink from './links/httpLink';
import typePolicies from './type-policies';

export default function createApolloClient() {
  const cache = new InMemoryCache({
    typePolicies,
    dataIdFromObject: (obj) => {
      let id = obj.id;
      if (!id) {
        const { __typename: typename } = obj;
        switch (typename) {
          case 'EligiblePeriod':
            return `${typename}:${obj.startTime}:${obj.endTime}`;
        }
      }
    },
  });

  const links = [authLink(), httpLink()];

  return new ApolloClient({
    link: from(links),
    cache,
    queryDeduplication: true,
    connectToDevTools: true,
  });
}
