var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext } from 'react';
import { Input, Form } from 'antd';
import { get } from 'lodash';
export const editableContext = React.createContext({});
const EditableCell = function (props) {
    const { editing, dataIndex, title, record, children } = props, restProps = __rest(props, ["editing", "dataIndex", "title", "record", "children"]);
    const { getFieldDecorator } = useContext(editableContext);
    return (React.createElement("td", Object.assign({}, restProps), editing ? (React.createElement(Form.Item, { style: { margin: 0 } }, getFieldDecorator(dataIndex, {
        rules: [
            {
                required: true,
                message: `Please Input ${title}!`,
            },
        ],
        initialValue: get(record, dataIndex),
    })(React.createElement(Input, { size: "small" })))) : (children)));
};
export default EditableCell;
