import React from 'react';
import I18n from 'i18n-js';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Layout, Menu, Icon } from 'antd';
import { getCurrentUser, logout } from 'grab-login/lib/helper';
import styles from './AppBar.less';
const { Item } = Menu;
const { Header } = Layout;
const AppBar = () => {
    const navigate = useNavigate();
    const currentUser = getCurrentUser();
    const handleClick = (item) => {
        switch (item.key) {
            case 'logout':
                logout();
                navigate('/login', { replace: true });
                break;
            default:
            // Do nothing
        }
    };
    return (React.createElement(Header, { className: styles.container },
        React.createElement(Dropdown, { overlay: React.createElement(Menu, { onClick: handleClick },
                React.createElement(Item, { key: "logout", className: styles.menu },
                    React.createElement(Icon, { type: "logout" }),
                    " ",
                    I18n.t('sessions.logout'))) },
            React.createElement("div", { className: styles.profile },
                React.createElement("img", { className: styles.avatar, alt: "avatar", src: require('public/images/avatar.svg') }),
                React.createElement("span", { className: styles.name }, currentUser ? currentUser.name : null),
                React.createElement(Icon, { type: "down" })))));
};
export default AppBar;
