import { gql } from '@apollo/client';
export const SUGGESTION_EVENT_METADATA = gql `
  fragment SuggestionEventMetadata on SuggestionEventMetadata {
    bookingCode
  }
`;
export const SUGGESTION_EVENT = gql `
  ${SUGGESTION_EVENT_METADATA}
  fragment SuggestionEvent on SuggestionEvent {
    suggestionEventActionType
    metadata {
      ...SuggestionEventMetadata
    }
    createdAt
    updatedAt
  }
`;
export const SUPERCHARGED_DAX_SUGGESTION_METADATA = gql `
  fragment SuperchargedDaxSuggestionMetadata on SuperchargedDaxSuggestionMetadata {
    dsPrediction
    etaPrediction {
      distance
      time
    }
  }
`;
export const SUGGESTION_METADATA = gql `
  ${SUPERCHARGED_DAX_SUGGESTION_METADATA}
  fragment SuggestionMetadata on SuggestionMetadata {
    expiry
    superchargedDax {
      ...SuperchargedDaxSuggestionMetadata
    }
  }
`;
export const SUGGESTION = gql `
  ${SUGGESTION_EVENT}
  ${SUGGESTION_METADATA}
  fragment SuggestionWithEvents on SuggestionWithEvents {
    createdAt
    driverID
    driverGeoHash
    events {
      ...SuggestionEvent
    }
    groupID
    metadata {
      ...SuggestionMetadata
    }
    sessionUID
    suggestionOption
    suggestionTarget
    suggestionTargetType
    suggestionType
    suggestionUID
    updatedAt
  }
`;
export const SUGGESTION_SESSION = gql `
  fragment SuggestionSession on SuggestionSession {
    sessionUID
    cityID
    driverID
    firstSuggestionStartTime
    lastSuggestionStartTime
    lastSuggestionExpireTime
  }
`;
