import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spinner } from '@components';
import ProtectedRoute from '@pages/app/protected-route';
import { isPermitted, Resource, Action } from '@helpers/permission';
import ShiftPerformanceSummary from '@pages/shift/shift-performance';
// -------------------------- Home --------------------------
const HomePage = React.lazy(() => import('src/pages/home'));
// -------------------------- Schedule --------------------------
const SchedulePage = React.lazy(() => import('src/pages/schedule'));
const CreateSchedulePage = React.lazy(() => import('src/pages/schedule/create'));
const ScheduleDetailPage = React.lazy(() => import('src/pages/schedule/schedule-detail'));
// -------------------------- Auto Capacity  --------------------------
const AutoCapacityPage = React.lazy(() => import('src/pages/schedule/auto-capacity'));
// -------------------------- Shift  --------------------------
const ShiftListPage = React.lazy(() => import('src/pages/shift'));
const CreateShiftPage = React.lazy(() => import('src/pages/shift/create'));
const EditShiftPage = React.lazy(() => import('src/pages/shift/edit'));
const ShiftDetailPage = React.lazy(() => import('src/pages/shift/shift-detail'));
// -------------------------- Auto Zone  --------------------------
const AutoZonePage = React.lazy(() => import('src/pages/auto-zone'));
const CreateAutoZonePage = React.lazy(() => import('src/pages/auto-zone/create'));
const AutoZoneDetailpage = React.lazy(() => import('src/pages/auto-zone/detail'));
// -------------------------- Streak  --------------------------
const StreakListPage = React.lazy(() => import('src/pages/streak'));
const StreakDetailPage = React.lazy(() => import('src/pages/streak/streak-detail'));
// -------------------------- Tools  --------------------------
const ExplainabilityPage = React.lazy(() => import('src/pages/tool/explainability'));
const ExplainabilityBatchDetailPage = React.lazy(() => import('src/pages/tool/explainability/BatchDetailPage'));
const ExplainabilityLeverDetailPage = React.lazy(() => import('src/pages/tool/explainability/LeverDetailPage'));
const ExplainabilityDriverLeverPage = React.lazy(() => import('src/pages/tool/explainability/suggestion/DriverLeverPage'));
const ExplainabilitySuggestionPage = React.lazy(() => import('src/pages/tool/explainability/suggestion/SuggestionPage'));
const SuperchargedDaxDebuggerPage = React.lazy(() => import('src/pages/tool/scd-debugger'));
const GeofenceUploaderPage = React.lazy(() => import('src/pages/tool/geofence-uploader/GeofenceUploaderPage'));
// -------------------------- Not Found  --------------------------
const NotFoundPage = React.lazy(() => import('src/pages/not-found'));
const renderRoutes = (routes) => routes.map((route) => {
    return (React.createElement(Route, { key: route.pathname, path: route.pathname, element: React.createElement(ProtectedRoute, { checkPermission: route.checkPermission }, route.component) }));
});
export const ROUTES = [
    {
        pathname: '/',
        component: React.createElement(HomePage, null),
    },
    {
        pathname: '/shifts',
        component: React.createElement(ShiftListPage, null),
        checkPermission: () => isPermitted(Resource.SHIFT, Action.READ),
    },
    {
        pathname: '/shifts/create',
        component: React.createElement(CreateShiftPage, null),
        checkPermission: () => isPermitted(Resource.SHIFT, Action.CREATE),
    },
    {
        pathname: '/shifts/:id',
        component: React.createElement(ShiftDetailPage, null),
        checkPermission: () => isPermitted(Resource.SHIFT, Action.READ),
    },
    {
        pathname: '/shifts/:id/edit',
        component: React.createElement(EditShiftPage, null),
        checkPermission: () => isPermitted(Resource.SHIFT, Action.UPDATE),
    },
    {
        pathname: '/shift-schedules/create',
        component: React.createElement(CreateSchedulePage, null),
        checkPermission: () => isPermitted(Resource.SHIFT, Action.CREATE),
    },
    {
        pathname: '/shift-schedules',
        component: React.createElement(SchedulePage, null),
        checkPermission: () => isPermitted(Resource.SHIFT, Action.READ),
    },
    {
        pathname: '/shift-schedules/:scheduleId',
        component: React.createElement(ScheduleDetailPage, null),
        checkPermission: () => isPermitted(Resource.SHIFT, Action.READ),
    },
    {
        pathname: '/shift-schedules/auto-capacity',
        component: React.createElement(AutoCapacityPage, null),
        checkPermission: () => isPermitted(Resource.SHIFT, Action.UPDATE),
    },
    {
        pathname: '/auto-zones',
        component: React.createElement(AutoZonePage, null),
        checkPermission: () => isPermitted(Resource.AUTO_ZONING, Action.READ),
    },
    {
        pathname: '/auto-zones/create',
        component: React.createElement(CreateAutoZonePage, null),
        checkPermission: () => isPermitted(Resource.AUTO_ZONING, Action.CREATE),
    },
    {
        pathname: '/auto-zones/:ids',
        component: React.createElement(AutoZoneDetailpage, null),
        checkPermission: () => isPermitted(Resource.AUTO_ZONING, Action.UPDATE),
    },
    {
        pathname: '/streaks',
        component: React.createElement(StreakListPage, null),
        checkPermission: () => isPermitted(Resource.VANGUARD_STREAK, Action.READ),
    },
    {
        pathname: '/streaks/:schemeId',
        component: React.createElement(StreakDetailPage, null),
        checkPermission: () => isPermitted(Resource.VANGUARD_STREAK, Action.READ),
    },
    {
        pathname: '/tool/explainability',
        component: React.createElement(ExplainabilityPage, null),
        checkPermission: () => isPermitted(Resource.EXPLAINABILITY, Action.READ),
    },
    {
        pathname: '/tool/explainability/batch/:batchID',
        component: React.createElement(ExplainabilityBatchDetailPage, null),
        checkPermission: () => isPermitted(Resource.EXPLAINABILITY, Action.READ),
    },
    {
        pathname: '/tool/explainability/lever/:leverID',
        component: React.createElement(ExplainabilityLeverDetailPage, null),
        checkPermission: () => isPermitted(Resource.EXPLAINABILITY, Action.READ),
    },
    {
        pathname: '/tool/explainability/lever/:leverID/driver/:driverID',
        component: React.createElement(ExplainabilityDriverLeverPage, null),
        checkPermission: () => isPermitted(Resource.EXPLAINABILITY, Action.READ),
    },
    {
        pathname: '/tool/explainability/suggestion/:suggestionUID',
        component: React.createElement(ExplainabilitySuggestionPage, null),
        checkPermission: () => isPermitted(Resource.EXPLAINABILITY, Action.READ),
    },
    {
        pathname: '/tool/scd-debugger',
        component: React.createElement(SuperchargedDaxDebuggerPage, null),
        checkPermission: () => isPermitted(Resource.EXPLAINABILITY, Action.READ),
    },
    {
        pathname: '/shift-performance/',
        component: React.createElement(ShiftPerformanceSummary, null),
        checkPermission: () => isPermitted(Resource.SHIFT, Action.READ),
    },
    {
        pathname: '/tool/geofence-uploader',
        component: React.createElement(GeofenceUploaderPage, null),
        checkPermission: () => isPermitted(Resource.STREAK, Action.CREATE),
    },
];
const AppRoutes = () => (React.createElement(React.Suspense, { fallback: React.createElement(Spinner, null) },
    React.createElement(Routes, null,
        renderRoutes(ROUTES),
        React.createElement(Route, { path: "*", element: React.createElement(ProtectedRoute, null,
                React.createElement(NotFoundPage, null)) }))));
export default AppRoutes;
