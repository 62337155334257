import Raven from 'raven-js';
import { ApolloLink } from '@apollo/client';
import { getToken, isAuthenticated } from 'grab-login/lib/helper';
import I18n from 'i18n-js';

import { notification } from 'antd';

const authLink = () =>
  new ApolloLink((operation, forward) => {
    const headers = {};

    if (!window.Grab.config.appId) {
      const err = new Error('Please configure `appId` in config.json.');
      Raven.captureException(err);
      throw err;
    }

    if (isAuthenticated()) {
      headers.Authorization = `Bearer ${getToken()}`;
    }

    // add the authorization to the headers
    operation.setContext({
      headers,
    });

    return forward(operation).map((response) => {
      const { errors } = response;

      if (errors) {
        const authError = errors.find(
          (error) => error.message === 'Unauthorized',
        );

        if (authError) {
          notification.open({
            message: I18n.t('permission.unauthorized_notification.title'),
            description: I18n.t(
              'permission.unauthorized_notification.description',
            ),
            duration: 0,
          });
          window.open('/login', '_self');
        }
      }

      return response;
    });
  });

export default authLink;
