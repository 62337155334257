var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { split, reduce } from 'lodash';
import { stringify } from 'qs';
import { login as grabLogin, logout as grabLogout, getToken as grabGetToken, isAuthenticated as grabIsAuthenticated, } from 'grab-login/lib/helper';
const CSRF_KEY = '__CSRF__';
const PERMISSIONS_KEY = '__PERMS__';
const SCOPES_KEY = '__SCOPES__';
const REFRESH_KEY = '__REFRESH__';
const USER_KEY = '__USER__';
const REDIRECT_KEY = '__REDIRECT__';
export const get = (url, params = {}) => fetch(`${window.Grab.config.apiUri}${url}?${stringify(params)}`, {
    headers: {
        Authorization: `Bearer ${grabGetToken()}`,
    },
}).catch((err) => {
    window.console.log(`[Error] uri: ${url} message:${err.message} ${err.response
        ? `response_data: ${JSON.stringify(err.response.data)}`
        : ''}`);
    throw err;
});
export const post = (url, data = {}) => fetch(`${window.Grab.config.apiUri}${url}`, {
    method: 'POST',
    headers: {
        Authorization: `Bearer ${grabGetToken()}`,
    },
    body: JSON.stringify(data),
}).catch((err) => {
    window.console.log(`[Error] uri: ${url} message:${err.message} ${err.response
        ? `response_data: ${JSON.stringify(err.response.data)}`
        : ''}`);
    throw err;
});
export const postForm = (url, data) => fetch(`${window.Grab.config.apiUri}${url}`, {
    method: 'POST',
    headers: {
        Authorization: `Bearer ${grabGetToken()}`,
    },
    body: data,
}).catch((err) => {
    window.console.log(`[Error] uri: ${url} message:${err.message} ${err.response
        ? `response_data: ${JSON.stringify(err.response.data)}`
        : ''}`);
    throw err;
});
const generateCsrfToken = () => {
    const token = Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
    window.Grab.storage.setItem(CSRF_KEY, token);
    return token;
};
const verifyCsrfToken = (csrf) => {
    const ok = csrf === window.Grab.storage.getItem(CSRF_KEY);
    window.Grab.storage.removeItem(CSRF_KEY);
    return ok;
};
export const login = (user, token, permissions, scopes) => {
    grabLogin(token.accessToken);
    window.Grab.storage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions || ''));
    window.Grab.storage.setItem(SCOPES_KEY, JSON.stringify(scopes || ''));
    window.Grab.storage.setItem(USER_KEY, JSON.stringify(user));
    if (token.refreshToken) {
        window.Grab.storage.setItem(REFRESH_KEY, token.refreshToken);
    }
    return token;
};
export const loginWithConcedo = (redirect = '/') => {
    const concedoUri = `${window.Grab.config.concedo.uri}/v1/oauth/authorize`;
    const params = stringify({
        redirect_uri: `${window.location.origin}/auth-callback`,
        client_id: window.Grab.config.concedo.clientId,
        state: [generateCsrfToken(), window.location.origin].join('|'),
    });
    window.Grab.storage.setItem(REDIRECT_KEY, JSON.stringify(redirect));
    window.location.href = `${concedoUri}?${params}`;
};
export const handleConcedoCallback = (csrf, code) => __awaiter(void 0, void 0, void 0, function* () {
    const redirect = JSON.parse(window.Grab.storage.getItem(REDIRECT_KEY));
    window.Grab.storage.removeItem(REDIRECT_KEY);
    if (code && verifyCsrfToken(csrf)) {
        const response = yield post('auth/login', {
            authCode: code,
        });
        const { user, token, permissions, scopes } = yield response.json();
        login(user, token, permissions, scopes);
        return redirect;
    }
    throw new Error('Authentication with Concedo failed.');
});
const deserializePermissions = () => reduce(JSON.parse(window.Grab.storage.getItem(PERMISSIONS_KEY) || ''), (acc, permission) => {
    const [resource, field, action] = split(permission, '.');
    return Object.assign(Object.assign({}, acc), { [resource]: Object.assign(Object.assign({}, acc[resource]), { [action]: true }) });
}, {});
export const getCurrentUser = (field) => {
    const grabUser = JSON.parse(window.Grab.storage.getItem(USER_KEY));
    if (grabUser) {
        const user = Object.assign(Object.assign({}, grabUser), { permissions: deserializePermissions(), scopes: JSON.parse(window.Grab.storage.getItem(SCOPES_KEY) || '') });
        return field ? user[field] : user;
    }
    return grabUser;
};
export const isAuthenticated = grabIsAuthenticated;
export const logout = () => {
    window.Grab.storage.removeItem(PERMISSIONS_KEY);
    window.Grab.storage.removeItem(USER_KEY);
    window.Grab.storage.removeItem(REFRESH_KEY);
    return grabLogout();
};
export const getToken = grabGetToken;
export const refreshNewAccessToken = () => __awaiter(void 0, void 0, void 0, function* () {
    const refreshToken = window.Grab.storage.getItem(REFRESH_KEY);
    if (!refreshToken) {
        throw new Error('Your user session is expired (1).');
    }
    try {
        const response = yield post('auth/refresh', {
            refreshToken,
        });
        const { user, token, permissions, scopes } = yield response.json();
        login(user, token, permissions, scopes);
    }
    catch (err) {
        throw new Error('Your user session is expired (2).');
    }
});
export const getCountries = () => {
    const scopes = JSON.parse(window.Grab.storage.getItem(SCOPES_KEY) || '');
    return scopes.reduce((accumulator, scope) => {
        if (scope.name === 'countries') {
            scope.values.forEach((value) => {
                if (!accumulator.some((item) => item.id === value.id)) {
                    accumulator.push(value);
                }
            });
        }
        return accumulator;
    }, []);
};
