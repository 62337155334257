import React, { useMemo } from 'react';
import { Tag, Table, Button } from 'antd';
import I18n from 'i18n-js';
import { size, map, forEach, isEmpty } from 'lodash';
import { formatShiftDateTime } from '@helpers/shift';
import useCities from '@helpers/useCities';
import styles from './ShiftPerformanceTable.less';
const DEFAULT_PAGE_SIZE = 10;
const ShiftPerformanceSummaryData = (props) => {
    var _a;
    const { shiftPerformance, countryCode, fetchMoreData } = props;
    const { getCity } = useCities(undefined, countryCode);
    const handleViewDetail = (shiftId) => {
        if (!shiftId) {
            return;
        }
        window.open(`/shifts/${shiftId}?country=${countryCode}`, '_blank');
    };
    let tz = '';
    if (!isEmpty(shiftPerformance)) {
        tz = ((_a = getCity(shiftPerformance[0].cityID)) === null || _a === void 0 ? void 0 : _a.timeZone) || '';
    }
    const columns = useMemo(() => [
        {
            title: I18n.t('shift_performance.shift_id'),
            key: 'shiftID',
            dataIndex: 'shiftID',
        },
        {
            title: I18n.t('shift.attribute.date'),
            key: 'date',
            render: (_, record) => {
                if (record.startTime && record.endTime) {
                    return formatShiftDateTime(tz, record.startTime, record.endTime);
                }
                return '';
            },
        },
        {
            title: I18n.t('shift.attribute.geofence'),
            dataIndex: 'geofenceCode',
        },
        {
            title: I18n.t('shift_performance.capacity'),
            key: 'capacity',
            dataIndex: 'dataSection',
            render: (_, record) => {
                let capacity = 0;
                map(record.dataSection, (data) => {
                    if (data.name.toLowerCase() === 'capacity') {
                        forEach(data.items, (item) => {
                            if (item.name === 'total_capacity') {
                                capacity = parseInt(item.value);
                            }
                        });
                    }
                });
                return React.createElement("span", null, capacity);
            },
        },
        {
            title: I18n.t('shift_performance.subscription_rate'),
            key: 'subscription_rate',
            dataIndex: 'dataSection',
            render: (_, record) => {
                let subscribedCount = 0;
                let totalCapacity = 0;
                forEach(record.dataSection, (data) => {
                    if (data.name.toLowerCase() === 'capacity') {
                        forEach(data.items, (item) => {
                            if (item.name === 'subscribed_driver') {
                                subscribedCount = parseInt(item.value);
                            }
                            if (item.name === 'total_capacity') {
                                totalCapacity = parseInt(item.value);
                            }
                        });
                    }
                });
                return (React.createElement("span", null,
                    ((subscribedCount / totalCapacity) * 100).toFixed(2),
                    "%"));
            },
        },
        {
            title: I18n.t('shift_performance.ar_cr_cp'),
            key: 'ar_cr_cp',
            dataIndex: 'dataSection',
            render: (_, record) => {
                let ar = 0;
                let cr = 0;
                let cp = 0;
                forEach(record.dataSection, (data) => {
                    if (data.name.toLowerCase() === 'ar/cr/cp') {
                        forEach(data.items, (item) => {
                            if (item.name === 'accepted_rate') {
                                ar = parseFloat(item.value);
                            }
                            if (item.name === 'cancelled_rate') {
                                cr = parseFloat(item.value);
                            }
                            if (item.name === 'completion_rate') {
                                cp = parseFloat(item.value);
                            }
                        });
                    }
                });
                return (React.createElement("span", null,
                    ar,
                    "%/",
                    cr,
                    "%/",
                    cp,
                    "%"));
            },
        },
        {
            title: I18n.t('shift_performance.online_hour'),
            key: 'online_hour',
            dataIndex: 'dataSection',
            render: (_, record) => {
                let oh = '';
                forEach(record.dataSection, (data) => {
                    if (data.name.toLowerCase() === 'others') {
                        forEach(data.items, (item) => {
                            if (item.name === 'oh') {
                                const splitOHValue = item.value.split('/');
                                const result = parseFloat(splitOHValue[1]);
                                if (!isNaN(result)) {
                                    oh = `${splitOHValue[0].replace(' hr', '%')}/${result.toFixed(2)}%`;
                                }
                            }
                        });
                    }
                });
                return React.createElement("span", null, oh);
            },
        },
        {
            title: I18n.t('shift_performance.tpoh'),
            key: 'tpoh',
            dataIndex: 'dataSection',
            render: (_, record) => {
                let tpoh = '';
                forEach(record.dataSection, (data) => {
                    if (data.name.toLowerCase() === 'others') {
                        forEach(data.items, (item) => {
                            if (item.name === 'tpoh') {
                                const result = parseFloat(item.value);
                                if (!isNaN(result)) {
                                    tpoh = `${result.toFixed(2)} hr`;
                                }
                            }
                        });
                    }
                });
                return React.createElement("span", null, tpoh);
            },
        },
        {
            title: I18n.t('shift_performance.mfg_job_bonus'),
            key: 'mfg_job_bonus',
            dataIndex: 'dataSection',
            render: (_, record) => {
                let mfg = 0;
                let jobBonus = 0;
                forEach(record.dataSection, (data) => {
                    if (data.name.toLowerCase() === 'payout') {
                        forEach(data.items, (item) => {
                            if (item.name === 'total_mfg') {
                                mfg = parseFloat(item.value);
                            }
                            if (item.name === 'total_bonus_payout') {
                                jobBonus = parseFloat(item.value);
                            }
                        });
                    }
                });
                return (React.createElement("span", null,
                    mfg,
                    " + ",
                    jobBonus,
                    " = ",
                    mfg + jobBonus));
            },
        },
        {
            title: I18n.t('shift_performance.labels'),
            key: 'labels',
            dataIndex: 'dataSection',
            render: (_, record) => {
                return map(record.label, (l) => {
                    if (l.toLowerCase().includes('low')) {
                        return (React.createElement(Tag, { color: "red", key: l }, l));
                    }
                    return (React.createElement(Tag, { color: "green", key: l }, l));
                });
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => {
                return (React.createElement("div", { className: styles.actions },
                    React.createElement(Button, { className: styles.iconButton, shape: "circle", icon: "search", onClick: () => {
                            handleViewDetail(record.shiftID);
                        } })));
            },
        },
    ], []);
    return (React.createElement("div", null,
        React.createElement(Table, { rowKey: "shiftID", columns: columns, dataSource: shiftPerformance, pagination: {
                defaultPageSize: DEFAULT_PAGE_SIZE,
                onChange: (page, pageSize = DEFAULT_PAGE_SIZE) => {
                    const items = page * pageSize;
                    if (size(shiftPerformance) <= items + pageSize) {
                        fetchMoreData();
                    }
                },
            } })));
};
export default ShiftPerformanceSummaryData;
