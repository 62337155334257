import { gql } from '@apollo/client';
export const CRITERIA_ITEM = gql `
  fragment CriteriaItem on ShiftQualificationItemValue {
    enabled
    value
    warningThreshold
    numerator
    denominator
  }
`;
export const CRITERIA = gql `
  ${CRITERIA_ITEM}
  fragment Criteria on ShiftQualificationDetails {
    acceptanceRate {
      ...CriteriaItem
    }
    cancellationRate {
      ...CriteriaItem
    }
    completionRate {
      ...CriteriaItem
    }
    onlineHours {
      ...CriteriaItem
    }
    minimumRides {
      ...CriteriaItem
    }
  }
`;
export const SHIFT_AND_TEMPLATE_BASIC = gql `
  fragment ShiftAndTemplateBasic on ShiftAndTemplateBasic {
    name
    totalCapacity
    isPrivate
  }
`;
export const LOCATION = gql `
  fragment Location on ShiftLocation {
    cityID
    geofenceCode
    geofenceVersion
    geofenceName
    geofenceCentre {
      latitude
      longitude
    }
  }
`;
export const CONSTRAINTS = gql `
  fragment Constraints on ShiftConstraints {
    driverGroups
    taxiTypeIDs
    forceAA
    multiTaxiTypeMode
    allowedTaxiTypeMode
    allowedTaxiTypeIDs
  }
`;
export const EARNING_DETAILS = gql `
  fragment EarningDetails on ShiftEarning {
    currency
    baseEarning
    perJobEarning
    ignoreJobsCount
  }
`;
export const SHIFT_FARE_CONFIGURATIONS = gql `
  fragment FareConfigurations on ShiftFareConfigurations {
    enabled
    fareType
    taxiTypeID
    config {
      value
    }
  }
`;
export const SHIFT = gql `
  ${SHIFT_AND_TEMPLATE_BASIC}
  ${LOCATION}
  ${CONSTRAINTS}
  ${EARNING_DETAILS}
  ${SHIFT_FARE_CONFIGURATIONS}
  fragment shiftRow on ListShiftRow {
    shiftID
    shiftBasicProps {
      basic {
        ...ShiftAndTemplateBasic
      }
      availableCapacity
    }
    shiftTime {
      startTime
      endTime
      publishTime
    }
    location {
      ...Location
    }
    constraints {
      ...Constraints
    }
    earningDetails {
      ...EarningDetails
    }
    state
    fareConfigurations {
      ...FareConfigurations
    }
  }
`;
export const SHIFT_TEMPLATE = gql `
  ${SHIFT_AND_TEMPLATE_BASIC}
  ${LOCATION}
  ${CONSTRAINTS}
  ${EARNING_DETAILS}
  ${SHIFT_FARE_CONFIGURATIONS}
  fragment shiftTemplateRow on ListShiftTemplateRow {
    id
    basicProps {
      basic {
        ...ShiftAndTemplateBasic
      }
    }
    startTime
    endTime
    publishTime
    location {
      ...Location
    }
    constraints {
      ...Constraints
    }
    earningDetails {
      ...EarningDetails
    }
    state
    fareConfigurations {
      ...FareConfigurations
    }
  }
`;
export const SHIFT_PERFORMANCE_ROW = gql `
  fragment shiftPerformanceRow on ShiftPerformance {
    id
    shiftID
    geofenceCode
    cityID
    startTime
    endTime
    label
    state
    metrics {
      title
      xFieldName
      yFieldName
      yFieldSize
      data {
        xFieldValue
        yFieldValue
      }
    }
    dataSection {
      name
      items {
        name
        value
      }
    }
  }
`;
