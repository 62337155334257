import React from 'react';
import I18n from 'i18n-js';
import classnames from 'classnames';
import styles from './BulkActions.less';
const BulkActions = (props) => {
    const { className, count, children } = props;
    return (React.createElement("div", { className: classnames(styles.bulkActions, className) },
        React.createElement("div", { className: styles.count }, I18n.t('bulk_actions.count', { count })),
        children));
};
export default BulkActions;
