import 'moment-timezone';
import moment from 'moment';
/**
 * @param tz timezone e.g. Asia/Singapore
 * @param starttime and
 * @param endtime time in ISO8601 'yyyy-MM-ddTHH:mm:ssZ' format
 */
export const formatShiftDateTime = (tz, starttime, endtime) => {
    const format = (t) => moment.tz(t, tz).format('ddd, DD MMM yyyy HH:mm');
    return `${format(starttime)} -> ${format(endtime)}`;
};
export const isFareTypeEnabled = (fareType, fareConfigurations) => {
    var _a;
    return (((_a = fareConfigurations === null || fareConfigurations === void 0 ? void 0 : fareConfigurations[0]) === null || _a === void 0 ? void 0 : _a.enabled) &&
        (fareConfigurations === null || fareConfigurations === void 0 ? void 0 : fareConfigurations[0].fareType) == fareType);
};
