import { HttpLink } from '@apollo/client';
import { find } from 'lodash';

import { isPrd, isStg } from '@helpers/env';

const interceptionPoints = [
  {
    condition: (fetchOptions) => {
      const { operationName } = JSON.parse(fetchOptions.body);
      if (operationName === 'getDriverLocationHistory') {
        window.console.log(`Intercepting ${operationName}`);
        return true;
      }
      return false;
    },
    result: {
      data: {
        driverLocationHistory: [
          {
            __typename: 'DriverLocationHistoryEntity',
            lng: 103.85496139526366,
            lat: 1.3985579970541446,
            time: '1704351600',
          },
          {
            __typename: 'DriverLocationHistoryEntity',
            lng: 103.85753631591797,
            lat: 1.3956406211658416,
            time: '1704351660',
          },
        ],
      },
    },
  },
  {
    condition: (fetchOptions) => {
      const { operationName } = JSON.parse(fetchOptions.body);
      if (operationName === 'getSuggestions') {
        window.console.log(`Intercepting ${operationName}`);
        return true;
      }
      return false;
    },
    result: {
      data: {
        heatmapsSuggestions: {
          __typename: 'GetSuggestionsResponse',
          suggestions: [
            {
              __typename: 'SuggestionWithEvents',
              driverID: 1,
              suggestionUID: 'e1ac733d-44e7-11ef-8000-000000000000',
              sessionUID: 's-sid-7096677-1721294818',
              createdAt: '2024-07-18T09:26:58Z',
              driverGeoHash: 'qqgux4',
              events: [
                {
                  __typename: 'SuggestionEvent',
                  suggestionEventActionType: 'REJECT',
                  metadata: {
                    bookingCode: '',
                  },
                  createdAt: '2024-07-18T09:27:05Z',
                  updatedAt: '2024-07-18T09:27:05Z',
                },
              ],
              groupID: 'g-1',
              metadata: {
                expiry: '2024-07-18T09:36:58.727746078Z',
                superchargedDax: {
                  dsPrediction: [
                    'qqgux1',
                    'qqgux1',
                    'qqgux1',
                    'qqgux1',
                    'qqgux1',
                    'qqgux1',
                  ],
                  etaPrediction: [
                    {
                      distance: 1780.3,
                      time: 445,
                    },
                  ],
                },
              },
              suggestionOption: 'NEW',
              suggestionTarget: 'qqgux1',
              suggestionTargetType: 'Circle',
              suggestionType: 'SUPERCHARGE',
              updatedAt: '2024-07-18T09:26:58Z',
            },
          ],
        },
      },
    },
  },
  {
    condition: (fetchOptions) => {
      const { operationName } = JSON.parse(fetchOptions.body);
      if (operationName === 'getSuggestion') {
        window.console.log(`Intercepting ${operationName}`);
        return true;
      }
      return false;
    },
    result: {
      data: {
        heatmapsSuggestion: {
          suggestion: {
            __typename: 'SuggestionWithEvents',
            driverID: 1,
            suggestionUID: 'e1ac733d-44e7-11ef-8000-000000000000',
            sessionUID: 's-sid-7096677-1721294818',
            createdAt: '2024-07-18T09:26:58Z',
            driverGeoHash: 'qqgux4',
            events: [
              {
                __typename: 'SuggestionEvent',
                suggestionEventActionType: 'REJECT',
                metadata: {
                  bookingCode: 'A-5V79AUQWWGBR',
                },
                createdAt: '2024-07-18T09:27:05Z',
                updatedAt: '2024-07-18T09:27:05Z',
              },
            ],
            groupID: 'g-1',
            metadata: {
              expiry: '2024-07-18T09:36:58.727746078Z',
              superchargedDax: {
                dsPrediction: [
                  'qqgux1',
                  'qqgux1',
                  'qqgux1',
                  'qqgux1',
                  'qqgux1',
                  'qqgux1',
                ],
                etaPrediction: [
                  {
                    distance: 1780.3,
                    time: 445,
                  },
                ],
              },
            },
            suggestionOption: 'NEW',
            suggestionTarget: 'qqgux1',
            suggestionTargetType: 'Circle',
            suggestionType: 'SUPERCHARGE',
            updatedAt: '2024-07-18T09:26:58Z',
          },
        },
      },
    },
  },
];

const httpLink = () =>
  new HttpLink({
    uri: `${window.Grab.config.apiUri}graphql`,
    fetch: (url, options) => {
      // No interception in online env, comment the line below to enable interception locally
      if (isPrd() || isStg()) return fetch(url, options);
      const interceptionPoint = find(interceptionPoints, ({ condition }) =>
        condition(options),
      );
      if (!interceptionPoint) return fetch(url, options);
      return new Promise((resolve, reject) => {
        const response = new Response(
          JSON.stringify(interceptionPoint.result),
          {
            status: 200,
            headers: { 'Content-Type': 'application/json' },
          },
        );
        resolve(response);
      });
    },
  });

export default httpLink;
