/* import { hot } from 'react-hot-loader/root'; */
import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import classnames from 'classnames';
import withServiceWorker from 'grab-service-worker';
import ProtectedRoute from '@pages/app/protected-route';
import { Spinner } from '@components';
import AppBar from '@pages/app/app-bar';
import AppContent from '@pages/app/app-content';
import AppSider from '@pages/app/app-sider';
import styles from './App.less';
import { isPrd } from '@helpers/env';
const LoginPage = lazy(() => import('src/pages/login'));
const AuthCallback = lazy(() => import('src/pages/login/auth-callback'));
const Graphiql = React.lazy(() => import('src/pages/graphiql'));
const App = () => {
    return (React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/login", element: React.createElement(LoginPage, null) }),
            React.createElement(Route, { path: "/auth-callback", element: React.createElement(AuthCallback, null) }),
            !isPrd() && React.createElement(Route, { path: "/graphql", element: React.createElement(Graphiql, null) }),
            React.createElement(Route, { path: "*", element: React.createElement(ProtectedRoute, null,
                    React.createElement(Layout, { className: classnames(styles.layout, {
                            'ant-layout-has-sider': true,
                        }) },
                        React.createElement(AppSider, null),
                        React.createElement(Layout, null,
                            React.createElement(AppBar, null),
                            React.createElement(AppContent, null)))) }))));
};
App.displayName = 'App';
export default withServiceWorker()(App);
