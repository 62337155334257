import React, { useState } from 'react';
import I18n from 'i18n-js';
import moment from 'moment';
import { Button, DatePicker, Select, Tooltip } from 'antd';
import { map } from 'lodash';
import useCities from '@helpers/useCities';
import { getCountries } from '@helpers/auth';
import { CityInput, GeofenceInput } from '@components';
import styles from './Filter.less';
const { RangePicker } = DatePicker;
export var FilterShiftPerformanceAggregateTime;
(function (FilterShiftPerformanceAggregateTime) {
    FilterShiftPerformanceAggregateTime["DAILY"] = "DAILY";
    FilterShiftPerformanceAggregateTime["WEEKLY"] = "WEEKLY";
    FilterShiftPerformanceAggregateTime["MONTHLY"] = "MONTHLY";
})(FilterShiftPerformanceAggregateTime || (FilterShiftPerformanceAggregateTime = {}));
const AggregateTimeMeta = new Map([
    [FilterShiftPerformanceAggregateTime.DAILY, 'daily'],
    [FilterShiftPerformanceAggregateTime.WEEKLY, 'weekly'],
]);
const Filter = (props) => {
    var _a;
    const { defaultValue, onApply } = props;
    const [countrySelected, setCountrySelected] = useState(defaultValue.countryId);
    const [citySelected, setCitySelected] = useState(defaultValue.cityId);
    const [startDateSelected, setStartDateSelected] = useState(defaultValue.startDate);
    const [endDateSelected, setEndDateSelected] = useState(defaultValue.endDate);
    const [geoSelected, setGeoSelected] = useState(defaultValue.geofenceCode);
    const [aggregateTimeSelected, setAggregateTimeSelected] = useState(defaultValue.aggregateTime);
    const [dirty, setDirty] = useState(false);
    const countries = getCountries();
    const { getCity } = useCities(undefined, countrySelected);
    const minDate = moment().subtract(60, 'day').endOf('day');
    const disableDate = (current) => current >= moment().endOf('day') || current < minDate;
    return (React.createElement("div", { className: styles.filter },
        React.createElement("div", { className: styles.attributes },
            React.createElement(Tooltip, { title: I18n.t('shift_filter.country_select_tooltip'), placement: "bottom" },
                React.createElement(Select, { className: styles.select, placeholder: "Country", value: countrySelected.toString(), onSelect: (val) => {
                        if (val !== countrySelected) {
                            setCountrySelected(val);
                            setCitySelected(undefined);
                            setGeoSelected(undefined);
                            setDirty(true);
                        }
                    } }, map(countries, ({ id, value }) => (React.createElement(Select.Option, { key: id, value: id, title: value }, value))))),
            React.createElement(Tooltip, { title: I18n.t('shift_filter.city_select_tooltip'), placement: "bottom" },
                React.createElement(CityInput, { className: styles.select, placeholder: "City", countryCode: countrySelected, value: citySelected, onChange: (cityId) => {
                        setCitySelected(cityId);
                        setGeoSelected(undefined);
                        setDirty(true);
                    } })),
            React.createElement(Tooltip, { title: I18n.t('shift_filter.geofence_select_tooltip'), placement: "bottom" },
                React.createElement(GeofenceInput, { className: styles.select, allowClear: true, cityCode: (_a = getCity(citySelected)) === null || _a === void 0 ? void 0 : _a.code, value: geoSelected, onSelect: (geofence) => {
                        if ((geofence === null || geofence === void 0 ? void 0 : geofence.code) !== geoSelected) {
                            setGeoSelected(geofence ? geofence.code : '');
                            setDirty(true);
                        }
                    } })),
            React.createElement(Tooltip, { title: I18n.t('shift_filter.time_select_tooltip'), placement: "bottom" },
                React.createElement(RangePicker, { className: styles.select, allowClear: false, format: "YYYY-MM-DD", value: [moment(startDateSelected), moment(endDateSelected)], disabledDate: disableDate, onChange: (val) => {
                        var _a, _b, _c, _d;
                        if (!val)
                            return;
                        if (val.length < 2)
                            return;
                        if (!((_a = val[0]) === null || _a === void 0 ? void 0 : _a.isSame(moment(startDateSelected)))) {
                            setStartDateSelected((_b = val[0]) === null || _b === void 0 ? void 0 : _b.format('YYYY-MM-DD'));
                            setDirty(true);
                        }
                        if (!((_c = val[1]) === null || _c === void 0 ? void 0 : _c.isSame(moment(endDateSelected)))) {
                            setEndDateSelected((_d = val[1]) === null || _d === void 0 ? void 0 : _d.format('YYYY-MM-DD'));
                            setDirty(true);
                        }
                    } })),
            React.createElement(Tooltip, { title: I18n.t('shift_filter.shift_type_select_tooltip'), placement: "bottom" },
                React.createElement(Select, { className: styles.select, placeholder: "Type", value: aggregateTimeSelected === null || aggregateTimeSelected === void 0 ? void 0 : aggregateTimeSelected.toString(), onSelect: (val) => {
                        if (val != aggregateTimeSelected) {
                            setAggregateTimeSelected(val);
                            setDirty(true);
                        }
                    } }, map(Array.from(AggregateTimeMeta.keys()), (key) => (React.createElement(Select.Option, { key: key, value: key, title: AggregateTimeMeta.get(key) }, AggregateTimeMeta.get(key))))))),
        React.createElement("div", { className: styles.buttons },
            React.createElement(Button, { disabled: !dirty, onClick: () => {
                    setCountrySelected(defaultValue.countryId);
                    setCitySelected(defaultValue.cityId);
                    setStartDateSelected(defaultValue.startDate);
                    setEndDateSelected(defaultValue.endDate);
                    setGeoSelected(defaultValue.geofenceCode);
                    setDirty(false);
                } }, I18n.t('shift_filter.reset')),
            React.createElement(Button, { type: "primary", disabled: !dirty || !countrySelected || !citySelected, onClick: () => {
                    onApply({
                        countryId: countrySelected,
                        cityId: citySelected,
                        startDate: startDateSelected,
                        endDate: endDateSelected,
                        geofenceCode: geoSelected,
                        aggregateTime: aggregateTimeSelected,
                    });
                    setDirty(false);
                } }, I18n.t('shift_filter.apply')))));
};
export default Filter;
