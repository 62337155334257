import Raven from 'raven-js';

// Custom event names
const UPDATE_INSTALLED = 'update-installed';

function register(callback) {
  if (process.env.NODE_ENV !== 'production' || !('serviceWorker' in navigator)) {
    return;
  }

  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location);

  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
    console.warn(`PUBLIC_URL is on a different origin (${publicUrl.origin}), service worker is not registered`);
    return;
  }

  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing;
        newWorker.addEventListener('statechange', () => {
          if (newWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              callback(UPDATE_INSTALLED);
            }
          }
        });
      });
    })
    .catch((error) => {
      Raven.captureException(error);
      console.error('Error during service worker registration:', error);
    });
}

export default {
  register,
};
