import React from 'react';
import { isEmpty } from 'lodash';
import { Skeleton } from 'antd';
import { gql, useQuery } from '@apollo/client';
export const GET_UNIQUE_DRIVER_COUNT = gql `
  query getUniqueDriverCount($groupIDs: [Float!]) {
    getUniqueDriverCount(groupIDs: $groupIDs) {
      driverCount
    }
  }
`;
const UniqueDriverCountDriverGroup = (props) => {
    var _a, _b;
    const { groupIDs } = props;
    const { loading, error, data } = useQuery(GET_UNIQUE_DRIVER_COUNT, {
        variables: {
            groupIDs: groupIDs,
        },
        skip: isEmpty(groupIDs),
    });
    if (loading) {
        return React.createElement(Skeleton, { paragraph: { rows: 1, width: 10 } });
    }
    if (error) {
        return React.createElement("span", null, "0");
    }
    return (React.createElement("span", null, ((_a = data === null || data === void 0 ? void 0 : data.getUniqueDriverCount) === null || _a === void 0 ? void 0 : _a.driverCount)
        ? (_b = data === null || data === void 0 ? void 0 : data.getUniqueDriverCount) === null || _b === void 0 ? void 0 : _b.driverCount
        : 0));
};
export default UniqueDriverCountDriverGroup;
