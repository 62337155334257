import React, { useMemo, useState } from 'react';
import { map, omit, lowerCase, keys, join } from 'lodash';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { FieldSelector } from '@components';
import { GeoToolsUrl } from '@helpers/externalUrl';
import styles from './SuggestionList.less';
const defaultColumnSelection = [
    'driverID',
    'groupID',
    'sessionUID',
    'suggestionUID',
    'suggestionType',
    'suggestionOption',
    'driverGeoHash',
    'suggestionTarget',
    'createdAt',
];
const SuggestionList = (props) => {
    const { driverID, suggestions } = props;
    const toGeoTool = (uid) => {
        window.open(GeoToolsUrl.nonBookingTripsDebugger(uid, driverID), '_blank');
    };
    const listData = map(suggestions, (sug) => (Object.assign(Object.assign({}, omit(sug, ['events', 'metadata', '__typename'])), { events: join(map(sug.events, 'suggestionEventActionType'), ', ') })));
    const [activeDataIndexes, setActiveDataIndexes] = useState(defaultColumnSelection);
    const columns = useMemo(() => map(activeDataIndexes, (ind) => {
        if (ind === 'suggestionUID') {
            return {
                title: lowerCase(ind),
                dataIndex: ind,
                render: (id) => (React.createElement(Link, { to: `/tool/explainability/suggestion/${id}` }, id)),
            };
        }
        return {
            title: lowerCase(ind),
            dataIndex: ind,
        };
    }), [activeDataIndexes]);
    return (React.createElement("div", { className: styles.list },
        React.createElement("div", { className: styles.toolbar },
            React.createElement(FieldSelector, { fields: keys(listData[0]), getFieldName: lowerCase, values: activeDataIndexes, onChange: (indexes) => {
                    setActiveDataIndexes(indexes);
                } })),
        React.createElement(Table, { rowKey: 'suggestionUID', columns: columns, dataSource: listData })));
};
export default SuggestionList;
